import { Injectable} from "@angular/core";

@Injectable()
export class QuoteService {
    
    public quotes = [
        {
            quote: "We want something with more of a ‘wow’ factor",
            author: "Theodoros Dionysiou",
            color: "#555",
        }, 
        {
            quote: "Think more, design less",
            author: "Theodoros Dionysiou",
            color: "#555",
        }, 
        {
            quote: "Can’t you just copy and paste it in?",
            author: "Theodoros Dionysiou",
            color: "#555",
        }, 
        {
            quote: "The copy seems to be in latin, but could be Italian or Spanish",
            author: "Theodoros Dionysiou",
            color: "#555",
        }, 
        {
            quote: "",
            author: "",
            color: "",
        }, 
        {
            quote: "",
            author: "",
            color: "",
        }, 
    ];
    constructor(){

    }
}