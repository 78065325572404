import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html'
})
export class ThankYouComponent implements OnInit {
  pageLoaded: boolean = false;
  hero_heading;
  hero_subheading;
  content;
  constructor(private _apiService: ApiService,  private router: Router) { }
  
  ngOnInit() {
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    if (this.router.url == '/thank-you/contact') 
    {
      this.hero_heading = "Thank you for your message.";
      this.hero_subheading = "We have received your request and will contact you shortly.";
      this.content = "If you require immediate assistance, please contact us: <br> Telephone: <a href='tel:+35722254501'>+357 22 254 501</a><br>Email: <a href='mailto:info@noveldigital.pro'>info@noveldigital.pro</a>";
    } 
    else if (this.router.url == '/thank-you/start-project') 
    {
      this.hero_heading = "Thank you for your message.";
      this.hero_subheading = "We have received your request and will contact you shortly.";
      this.content = "If you require immediate assistance, please contact us: <br> Telephone: <a href='tel:+35722254501'>+357 22 254 501</a><br>Email: <a href='mailto:info@noveldigital.pro'>info@noveldigital.pro</a>";
    } 
  }
}
