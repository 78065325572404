import { Injectable, EventEmitter } from "@angular/core";
import { Response } from '@angular/http';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ApiService {
    pageLoaded: EventEmitter<any>;

    pagesLoaded: boolean = false;
    
    apiURL: string = 'https://noveldigital.pro/api/';
    apiVersion: string = "wp-json/wp/v2/";
    apiLoaded = {};


    apiProjects = [];
    apiCategories = [];
    apiPosts = [];
    apiMembers = [];
    apiPostCategories = [];
    
    constructor(private http: HttpClient, private titleService: Title, private metaService: Meta) {
        this.pageLoaded = new EventEmitter;
    }
    
     
    getPages() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'pages?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    for(let i = 0; i < result.length; i++) {
                        this.apiLoaded[result[i].slug] = result[i];
                    }
                    this.pagesLoaded = true;
                    resolve(result);
                }
            )
        });
    }

    getPosts() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'posts?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiPosts = result;
                    resolve(result);
                }
            )
        });
    }


    getProjects() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'projects?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiProjects = result;
                    resolve(result);
                }
            )
        });
    }

    getMembers() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'members?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiMembers = result;
                    resolve(result);
                }
            )
        });
    }

    getCategories() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'categories?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiCategories = result;
                    resolve(result);
                }
            )
        });
    }
    getPostCategories() {
        return new Promise((resolve, reject) => {
            let result;
            
            this.http.get(this.apiURL + this.apiVersion + 'post_categories?per_page=100').subscribe(
                (response: Response) => {
                    result = response;
                    this.apiPostCategories = result;
                    resolve(result);
                }
            )
        });
    }

    SEO(page) {
        let meta = page.head_tags;
        let description = page.fields.seo_description ? page.fields.seo_description : "Novel Digital Agency | Google AdWords Partner. We offer Website Design &amp; Development, SMM and PPC Services. We help brands stay competitive. Call us!";
        let image = page.hero ? page.hero.large.url: "https://noveldigital.pro/assets/img/home.jpg";

        this.titleService.setTitle(meta.seo_title);
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ property: 'og:title', content: meta.seo_title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:image', content: image });
        this.metaService.updateTag({ name: 'twitter:title', content: meta.seo_title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        this.metaService.updateTag({ name: 'twitter:card', content: description });
        this.metaService.updateTag({ name: 'twitter:image', content: image });
    }
}