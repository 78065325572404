import { QuoteService } from './../../services/quote.service';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html'
})
export class WorkComponent implements OnInit {
  page;
  projects;
  projects_shown = [];
  projects_shown_n: number = 16;
  isLoadMoreAllowed: boolean = true;
  
  
  categories;
  category: number = 1;
  pageLoaded;
  quotes;
  temp;
  constructor(private _apiService: ApiService, private _quoteService: QuoteService) { }
  
  ngOnInit() {
    this.quotes = this._quoteService.quotes;
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['work'];
      if (this._apiService.apiProjects.length && this._apiService.apiCategories.length) {
        this.projects = this._apiService.apiProjects;
        this.projects.sort(this.sortProjects);
        this.initProjects();
        this.categories = this._apiService.apiCategories;
        this.categories.sort(this.sortCategories);
        this.initializePage();
      } else {
        this._apiService.getProjects().then((projects) => {
          this.projects = projects;
          this.projects.sort(this.sortProjects);
          this.initProjects();
          this._apiService.getCategories().then((categories) => {
            this.categories = categories;
            this.categories.sort(this.sortCategories);
            this.initializePage();
          });
        });  
      }
    }
  }
  
  initProjects(){
    this.projects_shown = [];
    
    let temp = [];
    //Create a temporary project list of certain category;
    for (let j=0; j< this.projects.length; j++) {
      if (this.projects[j].categories.includes(this.category)) {
        temp.push(this.projects[j]);
      }
    }
    if (temp.length > 16) {
      this.projects_shown_n = 16;
    } else {
      this.projects_shown_n = temp.length;
      this.isLoadMoreAllowed = false;
    }
    
    //Add the first posts in top this.projects_shown;
    for (let i = 0; i < temp.length; i++) {
      if (this.projects_shown.length >= this.projects_shown_n) {
        if (this.projects_shown.length == temp.length) {
          this.isLoadMoreAllowed = false;
        } 
        return;
      }
      this.projects_shown.push(temp[i]);
    }
  }
  
  
  
  loadProjects(){
    this.temp = [];
    //Create a temporary project list of certain category;
    for (let j=0; j< this.projects.length; j++) {
      if (this.projects[j].categories.includes(this.category)) {
        this.temp.push(this.projects[j]);
      }
    }
    //Check if all projects have already be loaded;
    if (this.projects_shown.length < this.temp.length) {
      
      //Check if remaining posts are more or less than 16;
      var diff = this.temp.length - this.projects_shown.length;
      if (diff >= 16) {
        this.projects_shown_n+=16;
      } else {
        this.projects_shown_n = this.projects_shown_n + diff;
        console.log(this.projects_shown_n);
      }
      
      //Add the remaining posts of loading in top this.projects_shown;
      this.projects_shown = [];
      for (let i = 0; i <= this.temp.length; i++) {
        if (this.projects_shown.length == this.projects_shown_n) {
          if (this.projects_shown.length == this.temp.length) {
            this.isLoadMoreAllowed = false;
          }
          return;
        } else {
          this.projects_shown.push(this.temp[i]);
        }
      }
    } else {
      this.isLoadMoreAllowed = false;
    }
  }
  
  sortProjects(a,b) {
    if (a.menu_order < b.menu_order) {
      return -1;
    } else if (a.menu_order > b.menu_order) {
      return 1;
    }
  }
  sortCategories(a,b) {
    return a.slug.localeCompare(b.slug);
  }
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
  }
  
  changeCategory(id) {
    this.category = id;
    this.isLoadMoreAllowed = true;
    this.initProjects();
  }
  
}
