import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareButtonsOptions } from '@ngx-share/core';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html'
})
export class BlogPostComponent implements OnInit {
  
  postSlug;
  post;
  similarPosts = [];
  author;
  pageLoaded;
  cs;
  
  customOptions: ShareButtonsOptions = {};
  
  constructor(private router: Router, private _apiService: ApiService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.route.params.subscribe((params)=>{
      this.postSlug = null;
      this.post = null;
      this.similarPosts = [];
      this.author = null;
      this.cs = null;
      this.pageLoaded = false;
      
      this.postSlug = params['slug'];
      
      this._apiService.getPosts().then((result: Array<any>) => {
        let posts = result;
        let post = posts.filter(obj => {
          return obj.slug == this.postSlug;
        });
        if (!post[0]) {
          this.router.navigate(['/blog']);
        }
        this.post = post[0];
        for (let i = 0; i < posts.length; i++) {
          for(let j = 0; j < this.post.fields.similar_articles.length; j++) {
            if (posts[i].slug == this.post.fields.similar_articles[j].post.post_name) {
              this.similarPosts.push(posts[i]);
            }
          }
        }
        this._apiService.getMembers().then((members: Array<any>)=> {
          let authors = members;
          let author =  authors.filter(obj => {
            return obj.slug == this.post.fields.author;
          });
          this.author = author[0];
          this._apiService.getPostCategories().then((categories: Array<any>) => {
            let cs = [];
            for (let i = 0; i < this.post.post_categories.length; i++) {
              let c = categories.filter(obj => {
                return obj.id == this.post.post_categories[i];
              });
              cs.push(c[0]);
            }
            this.cs = cs;
            this.initializePage();
          });
        });
      });
    });
  }
  
  
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.post);
  }
  
}
