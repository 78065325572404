//Angular stuff
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

//Components
import { HomeComponent } from './pages/home/home.component';
import { AgencyComponent } from "./pages/agency/agency.component";
import { WorkComponent } from "./pages/work/work.component";
import { StartProjectComponent } from './pages/start-project/start-project.component';
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { ServicesComponent } from "./pages/services/services.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { APIGuard } from "./services/api.guard";
import { ThankYouComponent } from "./pages/thank-you/thank-you.component";
import { ThankYouContactGuard } from "./services/thank-you-contact.guard";
import { ThankYouStartProjectGuard } from "./services/thank-you-start-project.guard";


const appRoutes: Routes = [
    {
        path: '',
        canActivate: [APIGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
                pathMatch: 'full',
            },
            {
                path: 'agency',
                component: AgencyComponent,
            },
            {
                path: 'services',
                component: ServicesComponent,
            },
            {
                path: 'work',
                component: WorkComponent,
            },
            {
                path: 'blog',
                component: BlogComponent,
            },
            {
                path: 'blog/:slug',
                component: BlogPostComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'thank-you/contact',
                component: ThankYouComponent,
                canActivate: [ThankYouContactGuard]
            },
            {
                path: 'start-project',
                component: StartProjectComponent,
            },
            {
                path: 'thank-you/start-project',
                component: ThankYouComponent,
                canActivate: [ThankYouStartProjectGuard]
            },
        ]
        
    },
    {
        path: '**',
        redirectTo: '',
    }
]
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes),
    ],
    exports: [
        RouterModule,
    ],
})

export class AppRoutingModule {
    
}