import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit {
  
  pageLoaded;
  page; 
  els: any; 
  constructor(private _apiService: ApiService) { }
  
  @HostListener('window:scroll', ['$event'])
  
  checkScroll() {
    if (this.pageLoaded) {
      const scrollPosition = window.pageYOffset;
      for(let i=0; i < this.els.length; i++) {
        if (scrollPosition >= this.els.item(i).offsetTop -150) {
          this.els.item(i).classList.add('visible');
        }
      }
    }
  }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['services'];
      this.initializePage();
    }
  }
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
    this.els = document.getElementsByClassName('service');
    setTimeout(()=>{
      this.els.item(0).classList.add('visible');
    }, 500);
    
  }
  
  
}
