import { ThankYouService } from './../../services/thank-you.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../services/api.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var grecaptcha: any;


@Component({
  selector: 'app-start-project',
  templateUrl: './start-project.component.html'
})
export class StartProjectComponent implements OnInit {
  
  pageLoaded;
  page;
  els: any; 
  


  formFirstName: string = "";
  formLastName: string = "";
  formCompanyName: string = "";
  formEmail: string = "";
  formWebsite: string = "";
  formPhone: string = "";
  formMessage: string = "";
  formRobot: number;

  errorMessage: string = "";

  captcha = false;
  @ViewChild('errorMessageEl') errorMessageEl;


  public lottieConfig1: Object;
  public lottieConfig2: Object;
  public lottieConfig3: Object;
  public lottieConfig4: Object;
  private anim1: any;
  private anim2: any;
  private anim3: any;
  private anim4: any;

  robot1 = this.robotNumber(0,10);
  robot2 = this.robotNumber(0,10);
  robot_result = this.robot1 + this.robot2;

  constructor(private _apiService: ApiService, private http: HttpClient, private router: Router, private tyService: ThankYouService) { }
  
  @HostListener('window:scroll', ['$event'])
  
  checkScroll() {
    if (this.pageLoaded) {
      const scrollPosition = window.pageYOffset;
      for(let i=0; i < this.els.length; i++) {
        if (scrollPosition >= this.els.item(i).offsetTop - 700) {
          this.els.item(i).classList.add('visible')
        }
      }
    }
  }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['start-project'];
      this.initializePage();
    }
    this.lottieConfig1 = {
      path: 'assets/icons/work.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig2 = {
      path: 'assets/icons/webdev.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig3 = {
      path: 'assets/icons/marketing.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig4 = {
      path: 'assets/icons/hotel.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
    
    this.els = document.getElementsByClassName('start-project-section');
    setTimeout(()=>{
      this.els.item(0).classList.add('visible');
    }, 500);
  }
  
  startProject() {
    if (this.robot_result != this.formRobot) {
      alert('Wrong Math, Are you a robot?')
      return;
    }
    this.errorMessage = "";
    this.errorMessageEl.nativeElement.classList.remove('open');


    //Get Categories
    let projectTypes = [];
    let webdev = <HTMLInputElement> document.getElementById("webdev");
    let marketing = <HTMLInputElement> document.getElementById("marketing");
    let branding = <HTMLInputElement> document.getElementById("branding");
    let hotel = <HTMLInputElement> document.getElementById("hotel");
    let policy = <HTMLInputElement> document.getElementById("checkbox");

    if (webdev.checked) {
      projectTypes.push("Web Development");
    }
    if (marketing.checked) {
      projectTypes.push("Digital Marketing");
    }
    if (branding.checked) {
      projectTypes.push("Branding");
    }
    if (hotel.checked) {
      projectTypes.push("Hotel Solutions");
    }
    

    //Get Budget Selected
    let e = <HTMLSelectElement> document.getElementById("budget");
    let budget = e.options[e.selectedIndex].text;

    this.http.get("https://noveldigital.pro/forms/start-project.php?" +
      "projectTypes=" + projectTypes.toString() + 
      "&firstname=" + this.formFirstName + 
      "&lastname=" + this.formLastName + 
      "&email=" + this.formEmail + 
      "&phone=" + this.formPhone + 
      "&website=" + this.formCompanyName + 
      "&company=" + this.formCompanyName + 
      "&message=" + this.formMessage +
      "&budget=" + budget +
      "&policy=" + policy.checked
    ).subscribe(
    (response: {success: boolean, message: string}) => {
      if (response.success) {
        this.tyService.allowStartProjectTY = true;
        this.router.navigate(['/thank-you/start-project']);
      } else {
        this.errorMessage = response.message;
        this.errorMessageEl.nativeElement.classList.add('open');
      }
    });
  }
  selectType(slug) {
    document.getElementById(slug).click();
  }

  handleAnimation1(anim: any) {
    this.anim1 = anim;
  } 
  handleAnimation2(anim: any) {
    this.anim2 = anim;
  } 
  handleAnimation3(anim: any) {
    this.anim3 = anim;
  } 
  handleAnimation4(anim: any) {
    this.anim4 = anim;
  } 

  play1() {
    this.anim1.stop();
    this.anim1.play();
  }
  play2() {
    this.anim2.stop();
    this.anim2.play();
  }
  play3() {
    this.anim3.stop();
    this.anim3.play();
  }
  play4() {
    this.anim4.stop();
    this.anim4.play();
  }

  robotNumber(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
}
