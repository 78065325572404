import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ThankYouService } from './../../services/thank-you.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
  
  pageLoaded;
  page;

  formFullName: string = "";
  formCompanyName: string = "";
  formEmail: string = "";
  formPhone: string = "";
  formMessage: string = "";

  errorMessage: string = "";
  @ViewChild('errorMessageEl') errorMessageEl;


  public lottieConfig1: Object;
  public lottieConfig2: Object;
  private anim1: any;
  private anim2: any;

  constructor(private _apiService: ApiService, private http: HttpClient, private router: Router, private tyService: ThankYouService) { }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['contact'];
      this.initializePage();
    }
    this.lottieConfig1 = {
      path: 'assets/icons/phone.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig2 = {
      path: 'assets/icons/179-email-book-outline-edited.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
  }

  contact() {
    this.errorMessage = "";
    this.errorMessageEl.nativeElement.classList.remove('open');
    let element = <HTMLInputElement> document.getElementById("checkbox");
    this.http.get("https://noveldigital.pro/forms/contact.php?" +
      "fullname=" + this.formFullName + 
      "&company=" + this.formCompanyName + 
      "&email=" + this.formEmail + 
      "&phone=" + this.formPhone + 
      "&message=" + this.formMessage + 
      "&policy=" + element.checked
    ).subscribe(
    (response: {success: boolean, message: string}) => {
      if (response.success) {
        this.tyService.allowContactTY = true;
        this.router.navigate(['/thank-you/contact']);
      } else {
        this.errorMessage = response.message;
        this.errorMessageEl.nativeElement.classList.add('open');
      }
    });
  }


  handleAnimation1(anim: any) {
    this.anim1 = anim;
  } 
  handleAnimation2(anim: any) {
    this.anim2 = anim;
  } 

  play1() {
    this.anim1.stop();
    this.anim1.play();
  }
  play2() {
    this.anim2.stop();
    this.anim2.play();
  }
  
  
}
