import { NgxCarousel } from 'ngx-carousel';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html'
})
export class AgencyComponent implements OnInit {
  
  page;
  pageLoaded;
  testimonial_number = 0;
  
  public carouselOne: NgxCarousel;
  
  constructor(private _apiService: ApiService) { }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['agency'];
      this.initializePage();
    }
    
    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      point: {
        visible: true
      },
      animation: 'lazy',
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner'
    }
  }
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
    // console.log(this.page.fields.testimonials[0].occupation);
  }
  
  testimonial(number) {
    this.testimonial_number = number;
  }
  
}
