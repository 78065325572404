import { QuoteService } from './services/quote.service';
import { APIGuard } from './services/api.guard';
import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShareButtonsModule } from '@ngx-share/buttons';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './extra/header/header.component';
import { FooterComponent } from './extra/footer/footer.component';
import { FooterHomeComponent } from './extra/footer-home/footer-home.component';
import { ApiService } from './services/api.service';
import { AgencyComponent } from './pages/agency/agency.component';
import { ServicesComponent } from './pages/services/services.component';
import { WorkComponent } from './pages/work/work.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StartProjectComponent } from './pages/start-project/start-project.component';
import { HttpClientModule } from '@angular/common/http';

import { NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';
import  'mousetrap'; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save mousetrap`)

import 'rxjs';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { ThankYouService } from './services/thank-you.service';
import { ThankYouContactGuard } from './services/thank-you-contact.guard';
import { ThankYouStartProjectGuard } from './services/thank-you-start-project.guard';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { SafePipe } from './safe.pipe';
import { LottieAnimationViewModule } from 'ng-lottie';


// import { RecaptchaModule } from "ng-recaptcha";



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    FooterHomeComponent,
    AgencyComponent,
    ServicesComponent,
    WorkComponent,
    BlogComponent,
    ContactComponent,
    StartProjectComponent,
    BlogPostComponent,
    ThankYouComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgxCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // RecaptchaModule,
    ShareButtonsModule.forRoot(),
    LottieAnimationViewModule.forRoot()
  ],
  providers: [ApiService, QuoteService, ThankYouService, APIGuard, ThankYouContactGuard, ThankYouStartProjectGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
