import { ApiService } from 'src/app/services/api.service';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private router: Router, private _apiService: ApiService){}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this._apiService.pageLoaded.emit(false);
      window.scrollTo(0, 0)
    });
  }
}
