import { ApiService } from './../../services/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {


  home_services;
  selected_service;

  page;
  pageLoaded;

  public typewriter_text: string = "STAY COMPETIVE";
  public typewriter_display: string = "";


  public lottieConfig1: Object;
  public lottieConfig2: Object;
  public lottieConfig3: Object;
  public lottieConfig4: Object;
  private anim1: any;
  private anim2: any;
  private anim3: any;
  private anim4: any;


  constructor(private _apiService: ApiService) { }


  ngOnInit() {
    document.getElementById('site-header').classList.add('home');
    document.getElementById('loader').classList.add('home');
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['home'];
      this.initializePage();
    } else {
      console.log('problem with running initial api call');
    }
    this.lottieConfig1 = {
      path: 'assets/icons/work.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig2 = {
      path: 'assets/icons/webdev.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig3 = {
      path: 'assets/icons/marketing.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
    this.lottieConfig4 = {
      path: 'assets/icons/hotel.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);

    //Design Js
    this.home_services = this.page.fields.services;

    setTimeout(()=>{
      document.getElementById('intro-text').classList.add('visible');
      this.changeService('work');
    },500);
  }


  handleAnimation1(anim: any) {
    this.anim1 = anim;
  }
  handleAnimation2(anim: any) {
    this.anim2 = anim;
  }
  handleAnimation3(anim: any) {
    this.anim3 = anim;
  }
  handleAnimation4(anim: any) {
    this.anim4 = anim;
  }

  play1() {
    this.anim1.stop();
    this.anim1.play();
  }
  play2() {
    this.anim2.stop();
    this.anim2.play();
  }
  play3() {
    this.anim3.stop();
    this.anim3.play();
  }
  play4() {
    this.anim4.stop();
    this.anim4.play();
  }


  // setSpeed(speed: number) {
  //     this.animationSpeed = speed;
  //     this.anim1.setSpeed(speed);
  // }


  changeService(service) {
    let line = document.getElementById('line');
    let content = document.getElementById('service-content');
    let els = document.getElementsByClassName('service');
    let services = [].slice.call(els);
    for (let s of services) {
      s.classList.remove('not-faded');
    }
    line.classList.remove('hotel', 'webdev', 'marketing', 'work');
    line.classList.add(service);
    document.getElementById(service).classList.add('not-faded');
    content.classList.remove('visible');
    setTimeout(()=>{
      this.selected_service = this.home_services.filter(obj => {
        return obj.slug == service;
      });
      this.selected_service = this.selected_service[0].content;
      content.classList.add('visible');
    }, 600);
  }


  ngOnDestroy(){
    document.getElementById('site-header').classList.remove('home');
    document.getElementById('loader').classList.remove('home');
  }
}
