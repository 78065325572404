import { ApiService } from './../../services/api.service';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @ViewChild('header') header;
  @ViewChild('hamburger') hamburger;
  @ViewChild('mobileMenu') mobileMenu;

  pageLoaded: boolean = false;
  constructor(private _apiService: ApiService) { }

  ngOnInit() {
    this._apiService.pageLoaded.subscribe((status)=> {
      this.pageLoaded = status;
    });
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    if (document.documentElement.scrollTop > 90) {
      this.header.nativeElement.classList.add('scrolled');
    } else {
      this.header.nativeElement.classList.remove('scrolled');
    }
  }

  toggleMobileMenu() {
    if (!this.hamburger.nativeElement.classList.contains('open')) {
      this.hamburger.nativeElement.classList.add('open');
      document.getElementById('mobile-menu-effect').classList.add('open');
      document.getElementById('body').classList.add('noscroll');
      setTimeout(()=>{
        this.mobileMenu.nativeElement.classList.add('open');
      }, 300);
    } else {
      this.hamburger.nativeElement.classList.remove('open');
      this.mobileMenu.nativeElement.classList.remove('open');
      document.getElementById('body').classList.remove('noscroll');
      setTimeout(()=>{
        document.getElementById('mobile-menu-effect').classList.remove('open');
      }, 300);
    }
  }
  leaveMobileMenu() {
      this.hamburger.nativeElement.classList.remove('open');
      this.mobileMenu.nativeElement.classList.remove('open');
      document.getElementById('body').classList.remove('noscroll');
      document.getElementById('mobile-menu-effect').classList.remove('open');
  }

  logoMobileMenu() {
    if (this.hamburger.nativeElement.classList.contains('open')) {
      this.hamburger.nativeElement.classList.remove('open');
      this.mobileMenu.nativeElement.classList.remove('open');
      document.getElementById('body').classList.remove('noscroll');
      document.getElementById('mobile-menu-effect').classList.remove('open');
    }    
  }
}
