import { HttpClient } from '@angular/common/http';
import { ThankYouService } from './../../services/thank-you.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  formEmail: string = "";
  
  message: string = "";
  @ViewChild('messageEl') messageEl;
  constructor(private http: HttpClient, private router: Router, private tyService: ThankYouService) { }

  ngOnInit() {
  }

  subscribe() {
    this.message = "";
    this.messageEl.nativeElement.classList.remove('open');
    this.http.get("https://noveldigital.pro/forms/newsletter.php?" +
      "type=" + "starting" +
      "&email=" + this.formEmail
    ).subscribe(
    (response: {success: boolean, message: string}) => {
      if (response.success) {
        this.message = response.message;
        this.messageEl.nativeElement.classList.add('open');
      } else {
        this.message = response.message;
        this.messageEl.nativeElement.classList.add('open');
      }
    });
  }

}
