import { ApiService } from './../../services/api.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { NgxCarousel } from 'ngx-carousel';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html'
})
export class BlogComponent implements OnInit {
  
  public carouselOne: NgxCarousel;
  
  posts;
  
  categories;
  category: number = 19;
  
  page;
  pageLoaded;
  //load more functionality

  _max_init_posts = 12;


  posts_shown = [];
  posts_shown_n: number = this._max_init_posts;


  isLoadMoreAllowed: boolean = true;
  temp;

  
  constructor(private _apiService: ApiService) { }
  
  @HostListener('window:scroll', ['$event'])
  
  checkScroll() {
    let el = document.getElementById('filters');
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= el.offsetTop/3) {
      el.classList.add('visible');
    }
  }
  
  ngOnInit() {
    if (this._apiService.pagesLoaded) {
      this.page = this._apiService.apiLoaded['blog'];

      if (this._apiService.apiPosts.length && this._apiService.apiPostCategories.length) {
        this.posts = this._apiService.apiPosts;
        this.posts.sort(this.sortPosts);
        this.categories = this._apiService.apiPostCategories;
        this.categories.sort(this.sortCategories);
        this.initPosts();
        this.initializePage();
      } else {
        this._apiService.getPosts().then((posts) => {
          this.posts = posts;
          this.posts.sort(this.sortPosts);
          
          this._apiService.getPostCategories().then((categories) => {
            this.categories = categories;
            this.categories.sort(this.sortCategories);
            this.initPosts();
            this.initializePage();
          });
        }); 
      }
    }
    
    
    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      point: {
        visible: true
      },
      animation: 'lazy',
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner'
    }
  }
  
  featuredPosts() {
    let featuredPosts =[];
    for(let i = 0; i < this.posts.length; i++) {
      if (this.posts[i].fields.featured) {
        featuredPosts.push(this.posts[i]);
      }
    }
    return featuredPosts;
  }
  
  sortPosts(a,b) {
    if (a.menu_order < b.menu_order) {
      return -1;
    } else if (a.menu_order > b.menu_order) {
      return 1;
    }
  }
  sortCategories(a,b) {
    return a.slug.localeCompare(b.slug);
  }
  
  
  initializePage(){
    this.pageLoaded = true;
    this._apiService.pageLoaded.emit(true);
    this._apiService.SEO(this.page);
  }
  
  changeCategory(id) {
    this.category = id;
    this.initPosts();
  }
  


  initPosts(){
    this.posts_shown = [];
    
    let temp = [];
    //Create a temporary project list of certain category;
    // console.log(this.posts[0].post_categories.includes(this.category));
    for (let j=0; j< this.posts.length; j++) {
      if (this.posts[j].post_categories.includes(this.category)) {
        temp.push(this.posts[j]);
      }
    }
    if (temp.length > this._max_init_posts) {
      this.posts_shown_n = this._max_init_posts;
      this.isLoadMoreAllowed = true;
    } else {
      this.posts_shown_n = temp.length;
      this.isLoadMoreAllowed = false;
    }
    // console.log(this.posts);
    //Add the first posts in top this.projects_shown;
    for (let i = 0; i < temp.length; i++) {
      if (this.posts_shown.length >= this.posts_shown_n) {
        if (this.posts_shown.length == temp.length) {
          this.isLoadMoreAllowed = false;
        } 
        return;
      }
      this.posts_shown.push(temp[i]);
    }
  
  }

  loadPosts(){
    this.temp = [];
    //Create a temporary project list of certain category;
    for (let j=0; j< this.posts.length; j++) {
      if (this.posts[j].post_categories.includes(this.category)) {
        this.temp.push(this.posts[j]);
      }
    }
    //Check if all projects have already be loaded;
    if (this.posts_shown.length < this.temp.length) {
      
      //Check if remaining posts are more or less than this._max_init_posts;
      var diff = this.temp.length - this.posts_shown.length;
      if (diff >= this._max_init_posts) {
        this.posts_shown_n+=this._max_init_posts;
      } else {
        this.posts_shown_n = this.posts_shown_n + diff;
        console.log(this.posts_shown_n);
      }
      
      //Add the remaining posts of loading in top this.projects_shown;
      this.posts_shown = [];
      for (let i = 0; i <= this.temp.length; i++) {
        if (this.posts_shown.length == this.posts_shown_n) {
          if (this.posts_shown.length == this.temp.length) {
            this.isLoadMoreAllowed = false;
          }
          return;
        } else {
          this.posts_shown.push(this.temp[i]);
        }
      }
    } else {
      this.isLoadMoreAllowed = false;
    }
  }
  
  
}
